import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header/header'
import Footer from './footer/footer'
import ContactModal from '../atoms/forms/contact/contact-modal/ContactModal'
import JoinEmailListModal from '../atoms/forms/join-email/join-email-list-modal/JoinEmailListModal'

import 'typeface-open-sans'
import 'typeface-lora'
import './layout.scss'


const Layout = class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showContactModal: false,
      showJoinEmailListModal: false
    }
  }

  launchContactModal = () => {
    this.setState({showContactModal: true})
  }
  closeContactModal = () => {
    this.setState({showContactModal: false})
    if(this.props.closeContactModal) {
      this.props.closeContactModal()
    }
  }

  launchJoinEmailListModal = () => { this.setState({showJoinEmailListModal: true}) }
  closeJoinEmailListModal = () => {
    this.setState({showJoinEmailListModal: false})
    if(this.props.closeJoinEmailListModal) {
      this.props.closeJoinEmailListModal()
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.launchContactModal && prevProps.launchContactModal !== this.props.launchContactModal) {
      this.setState({showContactModal: true})
    }

    if(this.props.launchJoinEmailListModal && prevProps.launchJoinEmailListModal !== this.props.launchJoinEmailListModal) {
      this.setState({showJoinEmailListModal: true})
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
                author
                description
              }
            }
          }
        `}
        render={data => (
          <React.Fragment>
            {/* <form name="contact" netlify netlify-honeypot="bot-field" hidden>
              <input type="hidden" name="form-name" value="contact" />
              <input type="hidden" name="bot-field" />
              <input type="text" name="first name" />
              <input type="text" name="last name" />
              <input type="email" name="email" />
              <input type="text" name="phone number" />
              <textarea name="message"></textarea>
            </form> */}
            <Header
              launchContactModal={this.launchContactModal}
              launchJoinEmailListModal={this.launchJoinEmailListModal}
              location={this.props.location}
            />
            {this.props.children}
            <Footer
              launchContactModal={this.launchContactModal}
              launchJoinEmailListModal={this.launchJoinEmailListModal}
              location={this.props.location}
            />
            <ContactModal
              show={this.state.showContactModal}
              onHide={this.closeContactModal}
            />
            <JoinEmailListModal
              show={this.state.showJoinEmailListModal}
              onHide={this.closeJoinEmailListModal}
            />
          </React.Fragment>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
