import React, { Component } from 'react'

import MobileNavigation from './navigation/mobile/navigation'
import DesktopNavigation from './navigation/desktop/navigation'

import './header.scss'


export default class Header extends Component {
    constructor(props) {
    super(props)

    this.state = {
      modalShow: false,
    }
  }

  launch = () => { this.setState({modalShow: true}) }
  close = () => { this.setState({modalShow: false}) }

  render() {
    return (
      <>
        <DesktopNavigation
          pathname={this.props.location.pathname}
          launchContactModal={this.props.launchContactModal}
        />
        <MobileNavigation
          pathname={this.props.location.pathname}
          launchContactModal={this.props.launchContactModal}
        />
      </>
    )
  }
}
