import React, { Component } from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Button from 'react-bootstrap/Button'

// import ContactButton from '../../atoms/forms/contact/contact-button/ContactButton'
// import JoinEmailListButton from '../../atoms/forms/join-email/join-email-list-button/JoinEmailListButton'

import whnLogo from '../../../images/whn-logo/whn-logo.svg'

import './footer.scss'


export default class Footer extends Component {
  handleKeyDown = (e) => {
    if(e.key === 'Enter' || e.key === ' ') {
      this.props.launchContactModal()
    }
  }
  render() {
    return (
      <StaticQuery
        query={
          graphql`
            query {
              allNavigationJson {
                edges {
                  node {
                    title
                    path
                    iconClass
                    onClick
                    # items {
                    #   itemTitle
                    #   iconClass
                    #   path
                    # }
                  }
                }
              }
            }
          `}
          render={data => {
            return (
              <footer className="footerStyles" style={{ paddingTop: "1rem" }}>
                <Container fluid>
                  <Row className="d-sm-none justify-content-center">
                    <Col xs={12} className="p-2 m-auto text-center">
                      {/* <JoinEmailListButton /> */}
                      <a
                        href="https://www.womenshormonenetwork.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={whnLogo}
                          alt="Womens Hormone Network Logo"
                          style={{ width: '85px' }}
                        />
                        {/* &nbsp;&nbsp;<sup><i style={{ fontSize: '.65rem', color: 'rgba(146, 66, 165, 1)' }} className="fas fa-external-link-alt"></i></sup> */}
                      </a>
                    </Col>
                    <Col xs={12} className="p-2 m-auto text-center">
                      <div className="d-flex justify-content-center">
                        <div className="p-2 align-self-center">
                          <a
                            href="https://www.facebook.com/groups/fixaflash/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fab fa-facebook-square facebook-social-icon" style={{ fontSize: '2.6rem' }}></i>
                          </a>
                        </div>
                        <div className="p-2 align-self-center">
                          <a
                            href="https://twitter.com/lovemyhormones"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fab fa-twitter-square twitter-social-icon" style={{ fontSize: '2.6rem' }}></i>
                          </a>
                        </div>
                        <div className="p-2 align-self-center">
                          <a
                            href="https://www.linkedin.com/in/gretchenjonesfixaflash/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fab fa-linkedin linkedin-social-icon" style={{ fontSize: '2.6rem' }}></i>
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="d-none d-sm-flex">
                    <div className="p-2 flex-grow-1">
                      {/* <JoinEmailListButton /> */}
                      {/* <div
                        style={{
                          padding: '1rem',
                          borderRadius: '.25rem',
                          border: '1px dashed rgba(146, 66, 165, 1)',
                          width: '145px'
                        }}
                      > */}
                        <a
                          href="https://www.womenshormonenetwork.org/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={whnLogo}
                            alt="Womens Hormone Network Logo"
                            style={{ width: '85px' }}
                          />
                          {/* &nbsp;&nbsp;
                          <sup>
                            <i
                              style={{
                                fontSize: '.65rem',
                                color: 'rgba(146, 66, 165, 1)'
                              }}
                              className="fas fa-external-link-alt"
                            />
                          </sup> */}
                        </a>
                        {/* <a
                          href="https://www.womenshormonenetwork.org/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button variant="primary" className="mr-4">Click here&nbsp;&nbsp;<sup><i style={{ fontSize: '.65rem' }} className="fas fa-external-link-alt"></i></sup></Button>
                        </a> */}
                      {/* </div> */}
                    </div>
                    <div className="p-2 align-self-center">
                      <a
                        href="https://www.facebook.com/groups/fixaflash/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook-square facebook-social-icon" style={{ fontSize: '2.6rem' }}></i>
                      </a>
                    </div>
                    <div className="p-2 align-self-center">
                      <a
                        href="https://twitter.com/lovemyhormones"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-twitter-square twitter-social-icon" style={{ fontSize: '2.6rem' }}></i>
                      </a>
                    </div>
                    <div className="p-2 align-self-center">
                      <a
                        href="https://www.linkedin.com/in/gretchenjonesfixaflash/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-linkedin linkedin-social-icon" style={{ fontSize: '2.6rem' }}></i>
                      </a>
                    </div>
                  </div>
                </Container>
                <Container fluid>
                  <Row>
                    <Col>
                      <hr style={{ margin: '1rem 0 2.5rem' }} />
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <Row>
                    {
                      data.allNavigationJson.edges.map((edge, index) => {
                        return(
                          <React.Fragment key={`item-${index}`}>
                            {
                              !edge.node.onClick
                              ?
                              <Col
                                xs={12}
                                md={6}
                                className="col-lg text-center text-md-left"
                                key={`menu-${index}`}
                                style={{
                                  marginBottom: '2rem'
                                }}
                              >
                              {/* {console.log(edge.node.path)} */}
                                <Link
                                  to={edge.node.path}
                                  className={
                                    this.props.location.pathname.includes(edge.node.path)
                                    ?
                                    "nav-link active"
                                    :
                                    "nav-link"
                                  }
                                >
                                  <h5>
                                    {edge.node.title}
                                    { edge.node.iconClass
                                      ? <>&nbsp; <i className={edge.node.iconClass}></i></>
                                      : null
                                    }
                                  </h5>
                                </Link>
                              </Col>
                              :
                              <Col
                                xs={12}
                                md={6}
                                className="col-lg text-center text-md-left"
                                key={`menu-${index}`}
                                style={{
                                  marginBottom: '2rem'
                                }}
                              >
                                <div
                                  role="button"
                                  className="nav-link"
                                  onClick={this.props.launchContactModal}
                                  onKeyDown={this.handleKeyDown}
                                  style={{
                                    cursor: "pointer"
                                  }}
                                  tabIndex={0}
                                >
                                  <h5>{edge.node.title}</h5>
                                </div>
                              </Col>
                            }
                          </React.Fragment>
                        )
                      })
                    }
                    {/* <Col
                      xs={12}
                      md={6}
                      className="col-lg text-center text-md-left"
                      style={{
                        marginBottom: '2rem'
                      }}
                    >
                      <p className="lead">Sitemap</p>
                      {
                        data.allNavigationJson.edges.map((edge, index) => {
                          // if (edge.node.items === "") {
                            return(
                                <ul key={`menu-${index}`} class="nav flex-column">
                                  <li class="nav-item">
                                    <Link
                                      to={edge.node.path}
                                      className={
                                        this.props.location.pathname.includes(edge.node.path)
                                        ?
                                        "nav-link active"
                                        :
                                        "nav-link"
                                      }
                                    >
                                      {edge.node.title}
                                    </Link>
                                  </li>
                                </ul>
                            )
                        })
                      }
                    </Col> */}
                  </Row>
                </Container>
                {/* <Container>
                  <Row className="justify-content-center">
                    <div className="d-flex">
                      <a
                        href="https://www.womenshormonenetwork.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={whnLogo}
                          alt="Womens Hormone Network Logo"
                          style={{ width: '125px' }}
                        />&nbsp;&nbsp;<sup><i style={{ fontSize: '.65rem' }} className="fas fa-external-link-alt"></i></sup>
                      </a>
                    </div>
                  </Row>
                </Container> */}
                <Container fluid>
                  <Row>
                    <Col>
                      <hr style={{ margin: '2.5rem 0 2.5rem' }} />
                    </Col>
                  </Row>
                </Container>
                <Container fluid>
                  <Row>
                    <Col>
                      <p style={{ textAlign: "center" }}>
                        Copyright © FixAFlash, {new Date().getFullYear()}. All rights reserved.
                      </p>
                    </Col>
                  </Row>
                </Container>
              </footer>
            )
          }
        }
      />
    )
  }
}
