import React, { useState } from 'react'

import { useForm } from 'react-hook-form'

import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'


function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactFormModal = (props) => {
  const { register, handleSubmit, formState, errors, reset } = useForm({
    mode: 'onChange',
  })
  const [state, setState] = React.useState({})
  const [feedbackMsg, setFeedbackMsg] = useState(null)
  const handleChange = e => setState({ ...state, [e.target.name]: e.target.value })
  const onSubmit = (data, e) => {
    JSON.stringify(data)
    e.preventDefault()
    // const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        // 'form-name': form.getAttribute('name'),
        'form-name': 'contact',
        ...state,
      }),
    })
      .then(response => {
        setFeedbackMsg(`Thanks for reaching out. I'll get back to you soon.`)
        reset()
        console.log(response)
      })
      .catch(error => {
        setFeedbackMsg("Oops, something went wrong. The form could not be submitted.")
        console.log(error)
      })
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="general-contact-form-modal"
      centered
    >
    <Modal.Header closeButton>
      <Modal.Title id="general-contact-form-modal">
        Contact Gretchen Jones
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {feedbackMsg && <Alert variant="success">{feedbackMsg}</Alert>}
      <Form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input type="hidden" name="form-name" value="contact" />
        <Form.Group controlId="formFirstName">
          <Form.Label>First name</Form.Label>
          <Form.Control
            className={
              !JSON.stringify(formState.touched.firstName)
              ?
                ""
              :
                JSON.stringify(formState.touched.firstName)
                &&
                formState.dirtyFields.firstName === undefined
                &&
                !errors.firstName
                ?
                  "is-invalid"
                :
                  errors.firstName
                  ?
                    "is-invalid"
                  :
                    "is-valid"
            }
            type="text"
            name="firstName"
            onChange={handleChange}
            ref={register({ required: true, maxLength: 200 })}
          />

          {((errors.firstName && errors.firstName.type === 'required') || formState.touched.firstName) &&
            <Form.Control.Feedback type="invalid">
              Please provide a first name.
            </Form.Control.Feedback>
          }

        </Form.Group>

        <Form.Group controlId="formLastName">
          <Form.Label>Last name</Form.Label>
          <Form.Control
            className={
              !JSON.stringify(formState.touched.lastName)
              ?
                ""
              :
                JSON.stringify(formState.touched.lastName)
                &&
                formState.dirtyFields.lastName === undefined
                &&
                !errors.lastName
                ?
                  "is-invalid"
                :
                  errors.lastName
                  ?
                    "is-invalid"
                  :
                    "is-valid"
            }
            type="text"
            name="lastName"
            onChange={handleChange}
            ref={register({ required: true, maxLength: 200 })}
          />

          {((errors.lastName && errors.lastName.type === 'required') || formState.touched.lastName) &&
            <Form.Control.Feedback type="invalid">
              Please provide a last name.
            </Form.Control.Feedback>
          }

        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            className={
              !JSON.stringify(formState.touched.email)
              ?
                ""
              :
                JSON.stringify(formState.touched.email)
                &&
                formState.dirtyFields.email === undefined
                &&
                !errors.email
                ?
                  "is-invalid"
                :
                  errors.email
                  ?
                    "is-invalid"
                  :
                    "is-valid"
            }
            type="email"
            name="email"
            onChange={handleChange}
            ref={register({
              required: true,
              pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            })}
          />

          {((errors.email && errors.email.type === 'required') || formState.touched.email) &&
            <Form.Control.Feedback type="invalid">
              Please provide your email address.
            </Form.Control.Feedback>
          }

        </Form.Group>

        <Form.Group controlId="formPhoneNumber">
          <Form.Label>Phone number</Form.Label>
          <Form.Control
            className={
              !JSON.stringify(formState.touched.phoneNumber)
              ?
                ""
              :
                JSON.stringify(formState.touched.phoneNumber)
                &&
                formState.dirtyFields.phoneNumber === undefined
                &&
                !errors.phoneNumber
                ?
                  "is-invalid"
                :
                  errors.phoneNumber
                  ?
                    "is-invalid"
                  :
                    "is-valid"
            }
            type="tel"
            maxLength="15"
            minLength="4"
            name="phoneNumber"
            onChange={handleChange}
            ref={register({
              required: true,
              maxLength: 20,
              minLength: 4,
              pattern: /^(?:\+?1[-. ]?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
            })}
          />

          {((errors.phoneNumber && errors.phoneNumber.type === 'required') || formState.touched.phoneNumber) &&
            <Form.Control.Feedback type="invalid">
              Please provide a valid phone number.
            </Form.Control.Feedback>
          }

        </Form.Group>

        <Form.Group controlId="formMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control
            className={
              !JSON.stringify(formState.touched.message)
              ?
                ""
              :
                JSON.stringify(formState.touched.message)
                &&
                formState.dirtyFields.message === undefined
                &&
                !errors.message
                ?
                  "is-invalid"
                :
                  errors.message
                  ?
                    "is-invalid"
                  :
                    "is-valid"
            }
            as="textarea"
            rows="4"
            name="message"
            onChange={handleChange}
            ref={register({ required: true })}
          />

          {((errors.message && errors.message.type === 'required') || formState.touched.message) &&
            <Form.Control.Feedback type="invalid">
              Please provide a message.
            </Form.Control.Feedback>
          }

        </Form.Group>

        <Button variant="primary" type="submit" className="float-right">
          Submit
        </Button>
      </Form>
    </Modal.Body>
  </Modal>
  )
}

export default ContactFormModal
