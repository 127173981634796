import React, { Component } from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
// import NavDropdown from 'react-bootstrap/NavDropdown'

import '../../header.scss'

import fixaflashLogo from '../../../../../images/logo/fixaflash_lotus_logo.svg'


export default class Header extends Component {
  handleKeyDown = (e) => {
    if(e.key === 'Enter' || e.key === ' ') {
      this.props.launchContactModal()
    }
  }
  render() {
    return (
      <StaticQuery
        query={
          graphql`
            query {
              allNavigationJson {
                edges {
                  node {
                    title
                    path
                    iconClass
                    onClick
                  }
                }
              }
            }
          `}
          render={data => {
            return (
              <div className="d-xl-none">
                <div className="p-3">
                  <Link to="/">
                    <img
                      className="fixaflashLogo img-fluid"
                      style={{
                        maxWidth: '20rem'
                      }}
                      src={fixaflashLogo}
                      alt="FixAFlash logo"
                    />
                  </Link>
                </div>
                <hr style={{ margin: '0' }} />
                <Navbar
                  bg="transparent"
                  expand="xl"
                >
                  <Navbar.Toggle
                    className="ml-auto"
                    aria-controls="mobile-navbar-nav"
                    style={{
                      border: 0,
                      outline: 'none'
                    }}
                  />
                  <Navbar.Collapse
                    id="mobile-navbar-nav"
                    className="navbarCollapse"
                  >
                    <Nav className="ml-auto">
                      {
                        data.allNavigationJson.edges.map((edge, index) => {
                          // if (edge.node.items !== '/contact/') {
                          return(
                            <React.Fragment key={`item-${index}`}>
                              {
                                !edge.node.onClick
                                ?
                                <Link
                                  className={
                                    this.props.pathname.includes(edge.node.path)
                                    ?
                                    "nav-link active"
                                    :
                                    "nav-link"
                                  }
                                  to={edge.node.path}
                                >
                                  {edge.node.title}
                                  { edge.node.iconClass
                                    ? <>&nbsp; <i className={edge.node.iconClass}></i></>
                                    : null
                                  }
                                </Link>
                                :
                                <div
                                  key={`item-${index}`}
                                  className={
                                    this.props.pathname.includes(edge.node.path)
                                    ?
                                    "nav-link active"
                                    :
                                    "nav-link"
                                  }
                                  onClick={this.props.launchContactModal}
                                  onKeyDown={this.handleKeyDown}
                                  role="button"
                                  tabIndex={0}
                                  style={{
                                    cursor: "pointer"
                                  }}
                                >
                                  {edge.node.title}
                                  { edge.node.iconClass
                                    ? <>&nbsp; <i className={edge.node.iconClass}></i></>
                                    : null
                                  }
                                </div>
                              }
                            </React.Fragment>
                          )
                          // } else {
                          //   return(
                          //     <NavDropdown
                          //       title={edge.node.title}
                          //       className={
                          //         edge.node.items.map((item, index) => {
                          //           return(
                          //             this.props.pathname.includes(item.path) && item.path !== "/join/" ? "active fadeMe" : "fadeMe"
                          //           )
                          //         })
                          //       }
                          //     >
                          //       {
                          //         edge.node.items.map((item, index) => {
                          //           return(
                          //             <Link
                          //               className="dropdown-item"
                          //               to={item.path}
                          //             >
                          //               {item.itemTitle}
                          //               {
                          //                 item.iconClass
                          //                 ?
                          //                 <>&nbsp; <i className={item.iconClass}></i></>
                          //                 :
                          //                 null
                          //               }
                          //             </Link>
                          //           )
                          //         })
                          //       }
                          //     </NavDropdown>
                          //   )
                          // }
                        })
                      }
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
                <hr style={{ margin: '0' }} />
              </div>
            )
          }
        }
      />
    )
  }
}
